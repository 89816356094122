@use "sass:math";

@import "pyrat_basic";

$size: 15px;
$cirle_color1: #7abe53;
$cirle_color2: #259cc4;


// Loader circle rotating green and blue.
// Used when the Iframe or Pop-up is being loading. Also in some message Pop-ups.
.loading_circle {
    width: 70px;
    height: 70px;
    background: $cirle_color2;
    background: -moz-linear-gradient(left,  $cirle_color2 0%, $cirle_color1 100%);
    background: -webkit-linear-gradient(left,  $cirle_color2 0%,$cirle_color1 100%,);
    background: linear-gradient(to right,  $cirle_color2 0%,$cirle_color1 100%,);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7abe53', endColorstr='#259cc4',GradientType=1 );


    border-radius: 50%;
    animation: spinner 1s infinite linear;
    position: relative;
    margin: 20px auto;

    &:after {
        position: absolute;
        display: block;
        top: 7px;
        left: 7px;
        width: 56px;
        height: 56px;
        content: "";
        background-color: $contrast-color;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
        overflow: hidden;

        .popup &,
        .popup_frame &,
        .ui-dialog & {
            background-color: $shade-color-93;
        }
    }
}

// No placed inside or connected with .loading_circle because they are not always used together
.loading_circle_container {
    margin: calc(50% - 35px);
    position: relative;
    width: 70px;
}

/* Safari */
@-webkit-keyframes spinner {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

// Small loader widget.
// Small two bubbles getting close and away one from each other.
// Used for some loading elements, like selectem or when a inner data is
// loading inside a popup after some inner changes (like enabling more actions in the pop-up)
.two_bubbles_loader_container {
    width: 40px;
    height: 24px;
    position: absolute;
    .two_bubbles_loader {
        position: absolute;
        width: $size;
        height: $size;
        top: 50%;
        left: 50%;
        margin-top: -($size*0.5);
        margin-left: -($size*0.5);
        perspective: 20px;

        &:before, &:after {
            content: " ";
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }

        &:before {
            left: -(math.div($size, 1.5));
            background: $cirle_color1;
            transform: translateZ(0);
            z-index: 1;
            -webkit-animation: rotation1 1.5s ease-out infinite;
            -moz-animation: rotation1 1.5s ease-out infinite;
            -o-animation: rotation1 1.5s ease-out infinite;
            animation: rotation1 1.5s ease-out infinite;
        }

        &:after {
            right: -(math.div($size, 1.5));
            background: $cirle_color2;
            transform: translateZ(0);
            z-index: 1;
            -webkit-animation: rotation2 1.5s ease-out infinite;
            -moz-animation: rotation2 1.5s ease-out infinite;
            -o-animation: rotation2 1.5s ease-out infinite;
            animation: rotation2 1.5s ease-out infinite;
        }
        @keyframes rotation1 {
            25% {
                left: 0;
                transform: translateZ(-1px);
            }

            50% {
                left: math.div($size, 1.5);;
                transform: translateZ(0);
            }

            75% {
                left: 0;
                transform: translateZ(2px);
                z-index: 2;
            }
        }

        @keyframes rotation2 {
            25% {
                right: 0;
                transform: translateZ(2px);
                z-index: 2;
            }

            50% {
                right: math.div($size, 1.5);
                transform: translateZ(0);
            }

            75% {
                right: 0;
                transform: translateZ(-1px);
            }
        }
    }
}
