@import "pyrat_basic";

/* Draw a horizontal rating bar.

This is basically a row of boxes that looks a bit like
a medical thermometer or a millipede. If you :hover over
a clickable_filling_rating_bar box (or one box has the
"selected" calls) all the previous boxes gets highlighted.

Mind that the boxes are displayed in reverse order.

HTML / SCSS examples:

  Basic clickable and filling bar:

    <div class="rating_bar">
      <span>Very good</span>
      <span>Good</span>
      <span>Fine</span>
      <span class="selected">Poor</span>
      <span>Very poor</span>
    </div>

    div.rating_bar {
      @include clickable_filling_rating_bar;
    }

  Bar for filter (clickable and some boxes highlighted
  without highlighting the previous ones):

    <div class="rating_bar">
      <span>Very good</span>
      <span>Good</span>
      <span class="particular_selected">Fine</span>
      <span class="particular_selected">Poor</span>
      <span>Very poor</span>
    </div>

    div.rating_bar {
      @include fixed_rating_bar;
    }

 Bar for lists or inline (without text and any :hover effects):

    <div class="rating_bar">
      <span></span>
      <span></span>
      <span></span>
      <span class="selected"></span>
      <span></span>
    </div>

    div.rating_bar {
      @include filling_rating_bar;
    }

*/


// basic rating bar layout
@mixin rating_bar {
    border-radius: 6px; // span radius + 1px
    border: 1px solid $shade-color-30;
    display: inline-block;
    padding: 1px;
    color: $shade-color-30;
    background-color: $contrast-color;

    span {
        user-select: none;
        background-color: $shade-color-93;
        min-width: 10px;
        min-height: 14px;
        margin: 0 0 0 2px;
        padding: 2px 6px;
        display: inline-block;

        /* Reverse the order, because there is no
           previous siblings selector in css for
           old browsers but this selector is
           required for good :hover behavior. */
        float: right;

        &.selected {
            background-color: $basic_color_400;
            font-weight: bold;
            color: $contrast-color;
        }

        &:last-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            margin-left: 0;
        }

        &:first-child {
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
            clear: both;
        }
    }

}

// rating bar with indication to be clickable
@mixin clickable_rating_bar {

    @include rating_bar;

    span {
        cursor: pointer;
    }

}

// rating bar that fills up all boxes left from the selected one
@mixin filling_rating_bar {

    @include rating_bar;

    span.selected ~ span {
        background-color: $basic_color_400;
        color: $shade-color-93;
    }

}

// rating bar that fills up to the hovered and the selected box and indicates to be clickable
@mixin clickable_filling_rating_bar {

    @include filling_rating_bar;
    @include clickable_rating_bar;

    span {

        span:hover,
        &:hover,
        &:hover ~ span {
            background-color: $basic_color_400;
            color: $shade-color-93;
        }

        &:hover ~ span.selected,
        &:hover ~ span.selected ~ span,
        &.selected ~ span:hover,
        &.selected ~ span:hover ~ span,
        &.selected:hover,
        &.selected:hover ~ span {
            background-color: $basic_color_600;
        }
    }

}
