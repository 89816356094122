@import "pyrat_icon_font";
@import "pyrat_basic";
@import "rating_bar";
@import "loader";

// add tailwind
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
    :focus-visible {
        @apply tw-outline-1 tw-outline tw-outline-shade-color-74;
        // Default css inherit from tailwind-preflight gives un ugly red border when focus:visible
    }

    *,
    ::before,
    ::after {
        @apply tw-box-content;
        // Default css inherit from tailwind-preflight is "box-sizing: border-box" and in pyrat works
        // better content-box. Execptions where border-box is needed are added in the special cases
    }

    h1, h2, h3, h4, h5, h6 {
        @apply tw-font-bold tw-mb-2;
        // Preflight define font normal but for h tags we want font bold and at least some margin bottom.
    }
}

body {
    font-family: $base-font-family;
    font-style: $base-font-style;
    font-weight: $base-font-weight;
    margin: 0;
    padding: 0;
    color: $primary-color;
    font-size: $medium-font-size;
    line-height: $medium-font-size + 2px;
}

// One attribute classes names
//
// Class names with one or two attributes for simple use everywhere.
//
// Style guide: one-attribute-classes

// Bold and/or italic font
//
// Simple one attribute classes names
//
// "strong" and "b" are chosen if we want an element to be font-weight bold.
//
// Markup:
//   <b>Bold value</b> | normal weight value | <strong>Bold value</strong><br/>
//   <i>Bold value</i> | normal weight value | <span class="italic">Bold value</span>
//
// Style guide: one-attribute-classes.weight-style
.strong, strong, b { font-weight: bold; }

i, .italic { font-style: italic; }

a,
a:link,
a:visited {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}

// Disabled elements
//
// If the element cannot be clicked then it get the disabled class.
// It applies to the inherited elements as well.
//
// Markup:
//   <span>Enabled text</span> | <span class="disabled">Disabled text</span>
//
// Style guide: one-attribute-classes.disabled
.disabled {
    color: $shade-color-30;
    opacity: 0.7;

    input { border: transparent;}
}

// Horizontal alignment / Floating
//
// Elements can be aligned to center, left or right or can float right or left on demand.
//
// Also they can float left or right.
//
// Markup:
//   <table><tbody><tr>
//   <td style="width: 120px; background: khaki" class="txt-left">{{or text 'Value'}}</td>
//   <td style="width: 120px; background: khaki" class="txt-center">{{or text 'Value'}}</td>
//   <td style="width: 120px; background: khaki" class="txt-right">{{or text 'Value'}}</td>
//   <tr><tbody><table>
//   <br>
//   <div class="left">Floating left</div>
//   <div class="right">Floating right</div>
//
// Style guide: one-attribute-classes.horizontal-alignment
.txt-center { text-align: center !important; }

.txt-right { text-align: right !important; }

.txt-left { text-align: left !important; }

.left { float: left; }

.right { float: right; }

// Vertical alignment
//
// Change the vertical alignment of inline, inline-block, inline-table, and table cell elements.
//
// Choices are
//    .align-top
//    .align-bottom
//
// Style guide: one-attribute-classes.vertical-alignment
.align_top {
    vertical-align: top;
}

.align_bottom {
    vertical-align: bottom;
}

// Clickable / Nowrap / Nobreak / hidden
//
// These non related elements can be used if needed.
// Clickable is common in td in list tables.
// Nobreak is used in links or data that should not break no mattes what (parent tanks in tank crossings for example)
// Nowrap also is common in list tables (E.g. Animal ID)
// Hidden is used to avoid adding "display: none" in inline html code everywhere.
//
// Markup:
//   <table><tbody><tr>
//   <td style="width: 40px; background: khaki" class="clickable">{{or text 'Value'}}</td>
//   <td style="width: 40px; background: khaki" class="nowrap">
//   {{or text 'Long text value that gets bigger than the space determined in the td'}}
//   </td>
//   <td style="width: 40px; background: khaki">
//     <span class="hidden">{{or text 'Value'}}</span></td>
//   <tr><tbody><table>
//
// Style guide: one-attribute-classes.click-hide-nowrap
.nowrap { white-space: nowrap; }

.prewrap { white-space: pre-wrap; }

.nobreak { word-break: keep-all !important; }

.clickable { cursor: pointer; }

.hidden { display: none; }

.clear { clear: both; }

// Text attributes
//
// Defined font-sizes to be used when it is not defined or should be different than the size inherited.
// The class .strikethrough has also the name .sacrificed because it has more sense in some uses, for example to define a dead animal.
//
// .overwritten                - Underline decoration if needed (in "a href elements" by default it is removed).
// .strikethrough              - If the element need to have the line-trought decoration after some action this classe should be added (E.g. discarded crossing ID in crossing list).
// .uppercase                  - Make the text style uppercase
// .lowercase                  - Make the text style lowercase
// .capitalize                 - Make the text style capitalize
// .breakable                  - Make the text breaks
// .font-medium                - Keeps a medium font-size / line-height (12px / 14px)
// .font-default     - Keeps a default font-size / line-height (14px / 16px)
// .font-defaultplus - Keeps a bigger than default font-size / line-height (15px / 18px)
//
// Markup:
//   <p  class="{{modifier_class}}"> {{or text 'Text example'}}</p>
//
// Style guide: one-attribute-classes.text-attributes
.overwritten { text-decoration: underline; }


.strikethrough,
.sacrificed {
    text-decoration: line-through !important;
    // It needs to overwrite any other place where text-decoration is defined with better tree structure.
}

.uppercase { text-transform: uppercase; }

.lowercase { text-transform: lowercase; }

.capitalize { text-transform: capitalize; }

.breakable {word-break: break-word;}

.font-medium {
    font-size: $base-font-size;
    line-height: $base-font-size + 2px;
}

.font-default {
    font-size: $medium-bigger-font-size;
    line-height: $medium-bigger-font-size + 2px;
}

.font-defaultplus {
    font-size: $large-font-size;
    line-height: $large-font-size + 3px;
}

// White background color
// Used if neccesary, please avoid it.
.white-bg { background-color: $contrast-color; }

// Female and male color are defined in cages and animal details as well as pedigree pop-ups.
.icon_female,
.female {
    background-color: lighten($high-level-color, 60);
    color: $primary-color;
}

.icon_male,
.male {
    background-color: lighten($second_color_900, 70);
    color: $primary-color;
}

.icon_unknown,
.unknown {
    background-color: $shade-color-93;
    color: $primary-color;
}

/* Print area colors */
// TODO: Do we need this? it may not be better the user to decide to prit it full-color or not?
body.print, body.print *,
body.print-layout, body.print-layout * {
    color: $primary-color !important;
    cursor: default !important;
}

// Elements
//
// The basic user interface elements.
//
// Style guide: elements

// Input
//
// :disabled    - The element is currently unusable and un-clickable.
//
// Markup:
//   <input class="{{modifier_class}}" placeholder="{{or placeholder 'Placeholder'}}"/>
//
// Style guide: elements.input
input,

// Select
//
// :disabled    - The element is currently unusable and un-clickable.
//
// Markup:
//   <select class="{{modifier_class}}">
//     <option value="" disabled selected>{{or placeholder 'Placeholder'}}</option>
//     <option value="1">{{or value 'Value'}}</option>
//   </select>
//
// Style guide: elements.select
select,

// Textarea
//
// :disabled    - The element is currently unusable and the text is not selectable (cannot be copied).
//
// Markup:
//   <textarea class="{{modifier_class}}" placeholder="{{or placeholder 'Placeholder'}}"></textarea>
//
// Style guide: elements.textarea
textarea,

button {
    /* ensure equal width of form elements */
    background-color: $contrast-color;
    color: $shade-color-30;
    /* Defined a default font-size for looking the same in different browsers. */
    font-size: $medium-font-size;
    margin: 1px 0;
    outline: none;
    box-sizing: border-box;
    border: 1px solid $shade-color-74;
    border-radius: 2px;
    &:hover {
        background-color: $shade-color-97;
        border-color: $shade-color-74;
    }

    &:focus {
        border-color: $shade-color-30;
    }

    &:disabled,
    &[readonly] {
        background-color: $shade-color-97;
        color: $shade-color-30;
        border: 1px solid $shade-color-93;
        background-image: none;

        &.plain_text_fallback {
            background-color: transparent;
            border: none;
            color: inherit;
        }
    }
}

// The height is defined in order to make select and inputs looks the same when one is next the other
input,
select,
button {
    height: 24px;
    &[type=radio],
    &[type=checkbox] {
        height: auto;
        vertical-align: middle;
    }
}

input,
textarea {
    padding: 1px 2px 1px 4px;
    resize: vertical;
}

select {
    background: $contrast-color no-repeat right 3px top scroll;
    appearance: none;
    padding: 1px 17px 1px 1px;
    -webkit-padding-start: 3px;
    padding-inline-start: unset;
    background-image: url('../images/icon_arrow_down.svg');
    display: inline-block;

    &.multiple {
        padding-right: 1px;
        background-image: none;

        &:hover {
            background-color: $contrast-color;
        }
    }

    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #036;

        option {
            color: transparent;
        }
    }

    option {
        background-color: $contrast-color;
        border: none;
    }
}

// Buttons right-aligned not in pop-up
//
// Style guide: Buttons-right-position-not-in-footer
//
// Same case that the buttons in the footer popup.
// In this case if there is the need to align them in the page (not pop-up)
// The class "buttons_container" does it.
// <div class="buttons_container" style="width: 500px; border: 1px solid;padding: 5px;">
//     <input type="submit" value="Cancel">
// </div>
.buttons_container {
    text-align: right;
    min-height: 30px;

    input,
    button,
    .button {
        margin: 0 5px;

        &:last-of-type {
            margin-right: 0 !important;
        }
        :first-of-type {
            margin-left: 0 !important;
        }
    }
}

// IE doesn't support the appearance attribute, so we remove it and keep the default style
@media all\0 {
    select, select:hover {
        background: $contrast-color;
        padding-right: 0px;
    }
}
@media all and (-ms-high-contrast:none) {
    select, select:hover {
        background: $contrast-color;
    }
}

// Link
//
// This is the new link style.
// - It can be used in any place where a click will redirect the user to
//   somewhere else or the content within the link text can be modified by clicking.
// - Don't use it as a replacement for a button (to start CRUD actions).
// - The link text should not contain a verb but either the data,
//   a summary of the data, or the kind of data behind the link.
//
// Markup:
//   <a class="subtle-link {{modifier_class}}">{{or text 'Link'}}</a>
//
// Style guide: elements.link
.subtle-link,
.subtle-link-parent a {
    // the subtle-link-parent is used when it is not possible to add the class to the
    // "a" element, either because it is a widget or because it load another iframe.
    // Recommended to don't use.
    border: 1px solid $shade-color-74;
    border-radius: 2px;
    cursor: pointer !important;
    padding: 0 2px 1px;
    font-weight: normal;
    margin-top: -1px;
    margin-bottom: 1px;
    text-decoration: none;
    word-break: break-all;

    &:hover {
        color: $second_color_700;
        background-color: $shade-color-90;
        border-color: $second_color_700;
    }
    // And the common used blue color is applied to the border and font-color when mouse over.

    .tabdiv & {
        margin-top: 0.5px;
    }
}

// Flexible container
//
// Flex box attributes applied to text that may be longer than
// the space available. The remaining text is shortened using
// ellipsis.
//
// Markup:
//   <div class="flexible_container" style="width: 175px; background-color: khaki">
//     <strong class="nowrap">Label:</strong>
//     <span class="ellipsis_text">Possibly very long value!</span>
//   </div>
//   <br/>
//   <div class="flexible_container" style="width: 175px; background-color: khaki">
//     <strong class="nowrap">Label:</strong>
//     <a class="ellipsis_text subtle-link">Possibly very long value!</a>
//   </div>
//
// Style guide: elements.flexible_container
.flexible_container {
    display: flex;

    .subtle-link {
        height: 16px;
    }

    &.flex_center_aligned {
        align-items: center;
    }

    &.flex_baseline_aligned {
        align-items: baseline;
    }
    &.flex_initial_aligned {
        align-items: initial;
    }

    .flex_inside_main_box {
        flex-grow: 1;
    }

    &.center_aligned {
        align-items: baseline;

        > * {
            margin-right: 5px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.ellipsis_text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


// Zebra style applied on flexible container
//
// Zebra style on tables makes a nice separation efect between one row and another.
// Similar effect is applied when input rows are one after another but not in a table.
//
// Markup:
// <div class="zebra_div_style">
//     <div class="grid flexible_container flex_center_aligned padding_5">
//         <label class="grid_column col-1-4 nowrap">Title 1</label>
//         <select class="grid_column col-3-4 col-last" ">
//     </select>
//     </div>
//     <div class="grid flexible_container flex_center_aligned padding_5">
//         <label class="grid_column col-1-4 nowrap">Title 1</label>
//         <select class="grid_column col-3-4 col-last" ">
//     </select>
//     </div>
// </div>
//
// Style guide: elements.zebra_flexible_container
.zebra_div_style {

    > div {

        border-bottom: 1px solid $shade-color-90;

        &:nth-child(2n+1) { background-color: $contrast-color; }
        &:nth-child(2n+0) { background-color: $shade-color-97; }

        &:hover {
            &:nth-child(2n+1) { background-color: darken($contrast-color, 2); }
            &:nth-child(2n+0) { background-color: darken($shade-color-97, 2); }
        }
    }
}

// Flexible container - Column mode
//
// Same flex box attributes than before but applied vertically.
// Used when we want one element to have their height plus the not used height.
//
// Markup:
//   <div class="flex_container_column" style="height: 100px; width: 175px;">
//     <div style="background-color: khaki">Label #1</div>
//     <div class="flex_inside_main_box" style="background-color: aliceblue">Label #2</div>
//     <div style="background-color: khaki">Label #3</div>
//   </div>
//
// Style guide: elements.flexible_container-column
.flex_container_column {
    // height must be defined, otherwise it doesn''t work. Better do inline.
    display: inline-flex;
    flex-direction: column;
    width: 100%;

    .flex_inside_main_box {
        flex-grow: 1;
    }
}

// Pre-defined width
//
// Classes defining rounded numbers.
// Avoid this class as much as possible, use instad grid or display flex.
//
// .width_50   - Define a 50 pixels width
// .width_80   - Define a 80 pixels width
// .width_100  - Define a 100 pixels width
// .width_150  - Define a 150 pixels width
// .width_200  - Define a 200 pixels width
// .width_full - Define a 100 % width (common use in input elements inside a td in filter for example)
//
// Markup:
//  <div style="background: khaki" class="{{modifier_class}}">{{or text 'Text example'}}</div>
//
// Style guide: one-attribute-classes.width_sizes
.width_50 { width: 50px; }
.width_80 { width: 80px; }
.width_100 { width: 100px; }
.width_150 { width: 150px; }
.width_200 { width: 200px; }
.width_250 { width: 250px; }
.width_300 { width: 300px; }
.width_half { width: 50%; }
.width_full { width: 100%;
    &.location-picker,
    &.tank-picker,
    &.cage-picker {
        width: 100% !important;
    }
}
// Caption simulation
//
// Used in reports for example because then it is not clear where the user is
//
//<h3 class="caption_main_title">Title here</caption>
div.caption_main_title {
    border: 1px solid $shade-color-90;
    background-color: $shade-color-97;
    padding: 7px 5px;
    color: $basic_color_600;
    font-size: $larger-font-size;
    font-weight: bold;
    margin: 5px 10px;
}


// Defined simple container
//
// For elements like PyRAT reports or welcome message we need a same margin and size container
//
// <div class="settings_container">
// text text
// </div>
.settings_container {
    width: 900px;
    margin: 15px 10px;

    .settings_title {
        background-color: $shade-color-97;
        color: $primary-color;
        padding: 5px;
        border: 1px solid darken($shade-color-90, 6);
    }
}

@media screen and (max-width: 950px) {
    .settings_container {
        width: 90%;
    }
}

// Pre-defined padding
//
// Shorthand padding classes to modify an elements appearance.
//
// The classes are named using the format padding_{sides}_{size}
//
// Where <i>sides</i> is one of:
// <ul>
// <li>t      - set padding-top</li>
// <li>b      - set padding-bottom</li>
// <li>l      - set padding-left</li>
// <li>r      - set padding-right</li>
// <li>x      - set both *-left and *-right</li>
// <li>y      - set both *-top and *-bottom</li>
// <li>blank  - set a padding on all 4 sides of the element</li>
//</ul>
//
//.padding_t_10   - Define a 10 pixels padding-top
//.padding_y_5    - Define a 5 pixels padding-top and -bottom
//.padding_15     - Define a 15 pixels padding on all 4 sides
//
// Markup:
//  <div style="background: khaki" class="{{modifier_class}}">{{or text 'Text example'}}</div>
//
// Style guide: one-attribute-classes.padding

.padding_t_5 {
    padding-top: 5px;
}

.padding_t_10 {
    padding-top: 10px;
}

.padding_b_10 {
    padding-bottom: 10px;
}

.padding_b_5 {
    padding-bottom: 5px;
}

.padding_l_10 {
    padding-left: 10px;
}

.padding_l_25 {
    padding-left: 25px;
}

.padding_r_5 {
    padding-right: 5px;
}

.padding_r_10 {
    padding-right: 10px;
}

.padding_x_5 {
    padding-right: 5px;
    padding-left: 5px;
}

.padding_y_5 {
    padding-top: 5px;
    padding-bottom: 5px;
}

.padding_y_10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding_5 {
    padding: 5px;
}

.padding_10 {
    padding: 10px;
}

.padding_15 {
    padding: 15px;
}

// Button
//
// - Buttons shall be used in any place where a click will start some action
//   like create, retrieve, update or delete a subject (so called CRUD actions).
// - Don't use it as a replacement for a link (to redirect the user to some other place).
// - The button text must contain a verb and may contain the kind of data but not
//   the actual data affected by the click itself.
//
// .confirm     - The most likely chosen button, within a group of buttons, is.
// .warn        - Something destructive will happen, when clicked.
// :disabled    - Usage (currently) not possible.
//
// Markup:
//   <input type="button" class="{{modifier_class}}" value="{{or text 'Button'}}">
//
// Style guide: elements.button
input[type=button],
input[type=submit],
input[type=reset],
.button,
button {
    border: 1px solid $shade-color-82;
    appearance: none;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    background-color: $shade-color-97;
    color: $primary-color;
    cursor: pointer;
    font-size: $medium-font-size;
    font-weight: $base-font-weight;
    padding: 1px 4px;
    outline: none;
    text-decoration: none;

    &::-moz-focus-inner {
        border: 0;
    }

    &:hover {
        background-color: $shade-color-90;
        border-color: $shade-color-74;
    }

    &:active {
        position: relative;
        top: 1px;
    }

    &.confirm {
        background-color: $basic_color_200;
        border-color: $basic_color_300;

        &:hover {
            background-color: $basic_color_300;
        }
    }

    &.warn {
        background-color: #eb675e;
        border-color: #cd5c5c;
        color: $contrast-color;

        &:hover {
            background-color: #cd5c5c;
        }
    }

    &.input_action {
        margin-left: 4px;
    }

    &.ui-dialog-titlebar-close {
        border: none;

        &:active {
            background-color: transparent !important;
        }
    }

    &:disabled,
    &:disabled:hover {
        background-color: $shade-color-90;
        color: $shade-color-74;
        border-color: $shade-color-59;
        cursor: not-allowed;
    }

// If buttons are listed, the margin makes them get more space one from each other.
    table.standard-table td & {
        margin: 3px;

// Being more than one button together, from the second one starts a margin left separating them.
        &:not(:first-child) {
            margin-left: 10px;
        }
    }
}


// Rating Bar
//
// Used for define the sperm's quality, it can be clickable "rating_clickable", filled mode ("rating_filling")
// or both combined ("rating_bar" & "rating_both" classes together).
//
// Markup:
// <div class="rating_bar rating_both">
//     <span>Very good</span>
//     <span>Good</span>
//     <span>Fair</span>
//     <span>Poor</span>
//     <span>Very poor</span>
// </div>
//
// Style guide: elements.rating-bar
div.rating_bar {

    &.rating_filling {
        @include filling_rating_bar;
    }

    &.rating_clickable {
        @include clickable_rating_bar;
    }

    &.rating_both {
        @include clickable_filling_rating_bar;
    }
}

.noty_buttons,
.ui-dialog,
.fake_popup_dialog,
.popup_frame,
.popup_footer {
    input[type=button],
    input[type=submit],
    input[type=reset],
    .button,
    button {
        &.confirm,
        &.ui-priority-secondary {
            background-color: $second_color_500;
            border-color: $second_color_700;
            color: $contrast-color;
            &:hover {
                background-color: $second_color_700;
            }
        }
        &:disabled,
        &:disabled:hover {
            background-color: $shade-color-90;
            color: $shade-color-74;
            border-color: $shade-color-59;
            cursor: not-allowed;
        }
    }
}


/* items that could be selected like list of <li> */
.selectable {
    cursor: default;
    padding: 2px 3px;
    margin: 1px;
    border: 1px solid transparent;
    border-radius: 2px;
    &.selected {
        font-weight: bold;
        background-color: $second_color_100 !important;   /* hsl(123, 42%, 80%) */
        border-color: $second_color_300 !important;       /* hsl(123, 25%, 60%) */
        background: linear-gradient(to bottom, rgba(255,255,255,0.2) 0%,rgba(130,130,130,0) 49%,rgba(125,125,125,0) 51%,rgba(0,0,0,0.05) 100%);
    }

    &:hover,
    &.active {
        background-color: $second_color_100;
        border-color: $shade-color-90;
    }

    &.mandatory {
        font-weight: normal;
        color: $shade-color-59 !important;
        background-color: inherit !important;
        border-color: transparent !important;

        &:hover {
            background-color: $shade-color-90 !important;
            border-color: $shade-color-90 !important;
        }

        &.selected {
            background-color: $shade-color-90 !important;
            border-color: $shade-color-59 !important;
            font-weight: bold;
        }
    }

    &:disabled { @extend .mandatory; }
}

// Icons
//
// Applying default icons from special icons font,
//
// The default icon is a square with rounded border in a middle dark gray color.
//
// No content should be added into the element!.
//
// The icon is generated based on two main class names.
// It should be written 'icon_button' for overall attributes
// and the wished drawing by writting 'icon_' plus the name of the icon desired.
//
// Complete list of icons can be found in 'extras/ressources/svg/Pyrat_icon_font/font_characters_table.html'
//
// .icon_eye     - Icon eye Example
// .icon_move    - Icon move Example
// .icon_rename  - Icon rename Example
//
// Markup:
//   <a class="icon_button {{modifier_class}}"></a>
//
// Style guide: elements.icons
.icon_button {
    border-radius: 2px;
    border: 1px solid $shade-color-74;
    font-size: 22px;
    line-height: 22px;
    font-family: $icons-font-family;
    text-decoration: none;

    span & {
        cursor: default;
    }

    a & {
        cursor: pointer;
    }

    // Icons (specific modifications)
    //
    // If needed they can be borderless (transparent, only drawing).
    // Also with blue or white background with the drawing white or with the inherited color
    //
    // .only_icon_img    - Define the icon borderless and transparent
    // .icon_dark_bg     - Define dark blue background
    // .icon_light_bg    - Define white background
    //
    // Markup:
    // <div style="background: #eee; padding: 5px; width: 30px">
    //     <a class="icon_button icon_eye {{modifier_class}}"></a>
    // </div>
    //
    // Style guide: elements.icons-specific
    &.only_icon_img {
        border: none;
        color: inherit;
        background-color: transparent;
    }

    &.icon_dark_bg {
        border-radius: 3px;
        background-color: $second_color_900;
        color: $contrast-color;
        border-color: $second_color_900;
    }

    &.icon_light_bg {
        border-radius: 3px;
        background-color: $contrast-color;
        color: inherit;
        border-color: $contrast-color;
    }
    // Icons (Add and remove)
    //
    // The icons for adding  '+' or deleting '❌' elements can be static or color dynanic
    //
    // .icon_add._add_interactive       - Plus symbol with change of color on mouse over
    // .icon_add._add_fixed             - Plus symbol with no change of color on mouse over
    // .icon_delete._delete_interactive - Cross symbol with change of color on mouse over
    // .icon_delete._delete_fixed       - Cross symbol with no change of color on mouse over
    //
    // Markup:
    //   <a class="icon_button only_icon_img {{modifier_class}}"></a>
    //
    // Style guide: elements.icons-add-remove
    &.icon_add {

        &::after { content: '+'; }

        &._add_fixed { color: $success-color; }
    }

    &._add_interactive {
        color: $shade-color-30;

        &:hover { color: $success-color; }
    }

    &.icon_delete {

        &::after {content: '❌'; }

        &._delete_fixed { color: $light-red; }
    }

    &._delete_interactive {
        color: $shade-color-30;

        &:hover { color: $light-red; }
    }

    &.icon_plus_bold::after { content: '˖'; }

    &.icon_move::after { content: '⮀'; }

    &.icon_rename::after { content: '✐'; }

    &.icon_trash::after { content: '🗑'; }

    &.icon_filter::after { content: '≚'; }

    &.icon_eye::after { content: '👁'; }

    &.icon_print::after { content: '⎙'; }

    &.icon_export::after { content: '✒'; }

    &.icon_pedigree::after { content: '👪'; }

    &.icon_message::after { content: '✉'; }

    &.icon_single_right_pointing::after { content: '›'; }

    &.icon_single_left_pointing::after { content: '‹'; }

    &.icon_double_right_pointing::after { content: '»'; }

    &.icon_double_left_pointing::after { content: '«'; }

    &.icon_arrow_right::after { content: '→'; }

    &.icon_arrow_left::after { content: '←'; }

    &.icon_arrow_up::after { content: '↑'; }

    &.icon_arrow_down::after { content: '↓'; }

    &.icon_done::after { content: '✓'; }

    &.icon_type_success::after { content: '✔'; }

    &.icon_type_error::after { content: 'X'; }

    &.icon_type_information::after { content: 'ℹ'; }

    &.icon_type_confirm::after { content: '？'; }

    &.icon_exclamation::after { content: '!'; }

    &.icon_male::after { content: '♂'; }

    &.icon_female::after { content: '♀'; }

    &.icon_unknown::after { content: '?'; }

    &.icon_death::after { content: '✝'; }

    &.icon_born::after { content: '*'; }

    &.icon_cage::after { content: '⧈'; }

    &.icon_stepback::after { content: '⏎'; }

    &.icon_single_up_arrow_bold::after { content: '△'; }

    &.icon_single_down_arrow_bold::after { content: '▽'; }

    &.icon_single_right_arrow_bold::after { content: '>'; }

    &.icon_single_left_arrow_bold::after { content: '<'; }

    &.icon_double_right_arrow_bold::after { content: '⇉'; }

    &.icon_double_left_arrow_bold::after { content: '⇇'; }

    &.icon_switch_on::after { content: '❒'; }

    &.icon_switch_off::after { content: '❑'; }

    &.icon_user::after { content: 'U'; }

    &.icon_history::after { content: '⏰'; }

    &.icon_star::after { content: '★'; }

    &.icon_gear::after { content: '☸'; }

    &.icon_flag::after { content: '⚑'; }

    &.icon_crossing_parent::after { content: '⟖'; }

    &.icon_crossing_children::after { content: '⟕'; }

    &.icon_position_grid::after { content: '⣿'; }

    &.icon_archive_mode::after { content: 'o'; }

    &.icon_pin::after { content: '⭹'; }

    &.icon_unpin::after { content: '⤬'; }

    &.icon_dna_1::after { content: '🔀'; }

    &.icon_dna_2::after { content: '↭'; }

    &.icon_dna_3::after { content: '⇹'; }

    &.icon_dna_4::after { content: '🧬'; }

    &.icon_ribbon_add::after { content: '⮰'; opacity: .25}
    &.icon_ribbon_add:hover::after { content: '⮷'; opacity: 1}

    &.icon_ribbon_remove::after { content: '⮰'; }
    &.icon_ribbon_remove:hover::after { content: '⮱'; }

    &.icon_text {
        font-family: $base-font-family;
        padding: 2px 3px;
        font-weight: bold;
        font-size: $large-font-size;
        margin-right: 5px;
        margin-left: 5px;

        &:last-child {
            margin-right: 0;
        }
    }
}

// Just until the input element can be changed for a span or a element
// special changes must be dropzone
input.icon_button {
    box-shadow: none;
    font-size: 22px;
    height: 18px;
    margin-left: 4px;
    margin-top: 0;
    padding: 2px;

    &:hover {
        background-color: transparent;
        border: none;
    }
}

/* IE loves to frame image links - but we don't */
/* IE8 alreayd does it, IE11 doesn't */
a img { border: none; }

// beauty elements.
// separator found in different filters.
.separator {
    border-top: 1px solid $shade-color-74;
    margin: 5px;
    clear: both;
 }

 // Loading circle
 //
 // Visible when loading data takes more than expected.
 // If it is inside the div class "loading_circle_container" it gets vertically centered.
 //
 // Markup:
 // <div class="loading_circle"></div>
 //
 // Style guide: elements.loading-circle


// Loading bubbles
//
// Small two bubbles getting close and away one from each other.
// Used for some loading elements, like selectem or when a inner data is
// loading inside a popup after some inner changes (like enabling more actions in the pop-up)
//
// Markup:
// <div style="height: 30px; width: 70px;">
// <span class="two_bubbles_loader_container">
//     <span class="two_bubbles_loader"></span>
// </span>
// </div>
//
// Style guide: elements.loading-bubbles

hr {
    border-top: 1px solid $shade-color-30;
    margin-bottom: 3px;
    margin-top: 3px;
    padding: 0;
}

// messages
.success,
.error {
    font-weight: bold;
    font-style: $base-font-style;
}

.success {
    color: $success-color;
}

.error {
    color: $error-color !important;

    @at-root {
        input#{&},
        textarea#{&},
        select#{&} {
            background-color: rgba($error-color, 0.5);
            border: 1px solid $error-color;
        }
    }
}

.warning {
    color: $warning-color !important;
}

.info {
    color: $warning-color !important;
}

// Mandatory field setting in some forms
input[type=text],
input[type=number],
textarea,
select,
span {
    &.invalid_data {
        @include invalid-data;
     }
}

// Priority/importance colors in table (example work request list)
// It should be uppercase and lowercase because the options in requestedetail in small pop-up selector
.medium,
.Medium {
    color: $medium-level-color !important;
}

.low,
.Low,
.lowest,
.Lowest {
    color: $low-level-color !important;
}

.high,
.High,
.highest,
.Highest,
.Emergency {
    color: $high-level-color !important;
}

// No data message
//
// If no content has been added into a list, this message should be displayed
//
// Markup:
// <div class="no_data_message">Content explaining the lack of data</div>
//
// Style guide: elements.no-data-message
.no_data_message {
    text-align: center;
    padding: 20px;
    font-size: $large-font-size;
    color: $shade-color-30;
}

//  Tooltip
.tooltip_icon {
    cursor: pointer;

    &:hover .tooltip_content {
        display: flex;
        flex-direction: column;
    }

    &:hover::after {
        color: $shade-color-30;
    }

    &::after {
        float: right;
        font-size: 14px;
        color: $shade-color-59;
        font-family: $icons-font-family;
        margin-right: 5px;
        margin-left: 5px;
        content: '?';
    }
}

.tooltip_content {
    all: initial;
    display: none;
    font-family: $base-font-family;
    font-size: $medium-font-size;
    line-height: $medium-font-size * 1.3;
    white-space: pre-wrap;
    position: absolute;

    $vertical-offset: -10px;
    $horizontal-offset: 5px;
    transform: translate($horizontal-offset, $vertical-offset);

    box-shadow: 2px 2px 5px $shade-color-59;
    background-color: $second_color_100;
    opacity: 0.95;
    padding: 4px 6px;
    color: $primary-color;
    visibility: visible;

    bottom: auto;
    margin-top: 25px;
    max-width: 250px;
    min-width: 150px;
    z-index: 2;

    * {
        margin-top: 0;
        margin-bottom: 5px;
    }

    &.to_the_left {
        transform: translate(calc(-100% + #{$horizontal-offset}), $vertical-offset);
    }

    &.to_the_top {
        transform: translate($horizontal-offset, calc(-100% + #{$vertical-offset}));
    }

    &.to_the_top.to_the_left {
        transform: translate(calc(-100% + #{$horizontal-offset}), calc(-100% + #{$vertical-offset}));
    }
}
// special hover effect for location in list views that don't have the tooltip_icon element
.tooltip_box {
    &:hover {
        .tooltip_content  {
            display: flex;
            flex-direction: column;
        }
    }
}


// elements applied in tables for content that may increase the height depending its data.
// Meant for places where content could grow, like a list.
.inner_expandible_container {
    // Default max-height. If needed more it should be written inline.
    max-height: 100px;
    overflow-y: auto;
    min-height: 20px;

    b,
    label {
        float: left;
        margin: 1px 0 0 2px;
    }

    p {
        margin: 5px 0;
    }

    a,
    a.subtle-link {
        float: left;
        margin: 0 0 2px 2px;
    }
}

// Meant for content that gets added to a table, like users or permissions.
.inner_table_container {
    border: 1px solid $shade-color-74;
    background-color: $contrast-color;
    // Default height. If needed more it should be written inline.
    margin-top: 3px;
    overflow-y: auto;
}


// Grid structure
//
// The grid structure helps to put more than one element in the same line covering the whole width without problem.
// (If the content fits inside each element or has not the "nowrap" class attribute).
//
// The class "frame_container" can be used to contain all the elements inside but it is not neccesary.
// It styles the data insde in a baseline structure. For a filter o a edit popup it may work nicely.
//
// The class "col-last" goes to the last col element and it remove the padding right needed to separate each col element form their sucesor.
// It can be used in a not last position but for naming purpose it is better to use the sibling class "col-narrow" (same effect).
//
// There are several percent options. The idea is to have 100% after the sum of al the col elements inside the div container.
// Inside the col element a input, select or textarea element can (or not) be 100% width.
//
// Markup:
//   <div class="popup_frame grid" style="width: 400px;">
//       <label class="grid_column col-1-10" style="background-color: khaki">{{or text 'Value'}}</label>
//       <label class="grid_column col-3-5" style="background-color: lavender">{{or text 'Value'}}</label>
//       <div class="grid_column col-3-10 col-last" style="background-color: lightsalmon">
//           <input class="width_full">
//       </div>
//   </div>
//   <div class="separator"></div>
//   <div class="popup_frame grid" style="width: 400px;">
//      <div class="frame_container">
//          <label for="name" class="grid_column col-1-3" style="background-color: lightblue">{{or text 'Value'}}</label>
//          <input type="text" class="grid_column col-2-3 col-last" style="background-color: linen">
//      </div>
//      <div class="frame_container">
//          <label class="grid_column col-1-4" style="background-color: honeydew">{{or text 'Value'}}</label>
//          <span class="grid_column col-1-4 col-last" style="background-color: aliceblue">
//            <input type="checkbox">
//          </span>
//          <label class="grid_column col-1-2" style="background-color: honeydew">{{or text 'Value'}}</label>
//     </div>
//     <div class="frame_container">
//         <label class="grid_column col-1-4" style="background-color: mistyrose">{{or text 'Value'}}</label>
//         <textarea class="grid_column col-3-4 col-last"rows="2" style="background-color: aquamarine"></textarea>
//     </div>
//   </div>
//   <div class="separator"></div>
//
// Style guide: elements.grid
.grid {
    &:after {
        clear: both;
        content: "";
        display: table;
    }

    // Simple way to display data with grid col elementes in a popup.
    .frame_container {
        display: flex;
        align-items: baseline;
        min-height: 28px;
        vertical-align: top;

        > * {
            vertical-align: top;
        }

        input[type="checkbox"] {
            margin-top: 5px;
            vertical-align: baseline;
        }
    }

    .grid_column {
        float: left;
        padding-right: 5px;
        box-sizing: border-box;

        &.col-narrow,
        &.col-last {
            padding-right: 0 !important;
        }

        &.col-1-1   { width: 100%; }
        &.col-19-20 { width: 95%; }
        &.col-11-12 { width: 91.66%; }
        &.col-9-10  { width: 90%; }
        &.col-5-6   { width: 83.33%; }
        &.col-4-5   { width: 80%; }
        &.col-3-4   { width: 75%; }
        &.col-7-10  { width: 70%; }
        &.col-2-3   { width: 66.66%; }
        &.col-13-20 { width: 65%; }
        &.col-3-5   { width: 60%; }
        &.col-11-20 { width: 55%; }
        &.col-1-2   { width: 50%; }
        &.col-9-20  { width: 45%; }
        &.col-5-12  { width: 41.66%; }
        &.col-2-5   { width: 40%; }
        &.col-1-3   { width: 33.33%; }
        &.col-3-10  { width: 30%; }
        &.col-1-4   { width: 25%; }
        &.col-1-5   { width: 20%; }
        &.col-1-6   { width: 16.66%; }
        &.col-3-20  { width: 15%; }
        &.col-1-8   { width: 12.5%; }
        &.col-1-10  { width: 10%; }
        &.col-1-12  { width: 8.33%; }
        &.col-3-40  { width: 7.5%; }
        &.col-1-15  { width: 6.66%; }
        &.col-1-20  { width: 5%; }
    }
}

// Examples
//
// Style guide: example


// Form
//
// Markup:
//   <form>
//     <div><b>Line / Strain:</b> {{> "elements.link" text="129S1/SvImJ"}}</div>
//     <div><b>Owner:</b> {{> "elements.select" value="Username" placeholder="Select a User"}}</div>
//     <div>
//       {{> "elements.button" text="Cancel"}}
//       {{> "elements.button" modifier_class="confirm" text="Apply"}}
//     </div>
//   </form>
//
// Style guide: example.form



div.fixed-header-scrolling-table {

    position: relative;

    .filler {
        padding: 0 15px;
    }

    table:first-of-type {

        $head-row-height: 27px;
        overscroll-behavior-y: none;

        thead {

            th {
                height: $head-row-height;
                position: -webkit-sticky;
                position: -moz-sticky;
                position: -o-sticky;
                position: -ms-sticky;
                position: sticky;
                z-index: 1;

            }

            @for $row from 1 through 4 {
                tr:nth-child(#{$row}) th {
                    top: $head-row-height * ($row - 1);
                }
            }

        }

    }


}
